// Core

@import "core";

// Fonts
// @import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');
@import url('https://fonts.googleapis.com/earlyaccess/notosanskr.css');

// Widgets

@import "widgets";

// Components

@import "components";

// Layout Components

@import "layout-components";

// Layout Blueprints

@import "layout-blueprints";


.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

// Temporary CSS define area
.app-sub--header {
  position: fixed;
  background: $maru-bg-color;
  color: $white;
  display: flex;
  padding: 0 20px;
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  border-bottom: 1px solid;
  border-color: rgba($white, 0.15);

  justify-content: space-between;

  a {
    color: $white;
    text-decoration: none;
    &:hover,:active {
      color: $white
    }
  }
}

.MuiPopover-root {
  .MuiPaper-root, .MuiMenu-paper, .MuiPopover-paper, .MuiPaper-rounded {
    box-shadow: 0 0.313rem 0.8rem rgba(0, 0, 0, 0.5), 
                0 0.126rem 0.225rem rgba(0, 0, 0, 0.3);

    .MuiList-root {
      .MuiButtonBase-root, .MuiListItem-root, .MuiMenuItem-root {
        border-radius: 0;
        .menus-label-icon {
          margin-right: 5px;
        }

        &.project-menus-item {
          font-size: $sidebar-navlink-font-size;
        }
      }
    }
  }
}

.app-content--inner {
  padding-top: 0.750rem;

  .MuiBreadcrumbs-root {
    font-size: 0.813rem;
    padding-bottom: 0.750rem;
  }
}

.admin-invite-member {
  .MuiInput-underline {
    &:hover:not(.Mui-disabled):before {
      border-width: 1px;
    }
    &::after {
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.5);
    }
  }

  .input-member-email {
    width: 250px;
  }
  .input-member-role {
    width: 200px;
  }
}

.mui-date-picker {
  div.MuiInput-root {
    input.MuiInput-input {
      padding-top: 3px;
    }
  }
}

.apm-status-icon {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
}

.datatable-loading-logo {
  width: 10rem;
  height: 10rem;
  background-image: url("../assets/images/loading/openmaru_loading.gif");
  background-size: contain;
  background-repeat: no-repeat;
}

.popup-background {
  width: 100vw;
  height: 100vh;
  padding: 1.2rem 1rem;
  background-image: linear-gradient(to top, #ed1b23, #f65056);
  .popup-inner {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    background-color: $white;
  }
}

.project-payment-edit-form {
  .contracts-information {
    display: flex;
    border-left: 1px solid #d3d2d2;

    .contract-list {
      height: 45%;

      .contract-list-table {
        width: 100%;
        padding: 0;
        margin-bottom: 1rem;
        overflow-y: auto;
  
        box-shadow: none;
        border-radius: 0;
        border: 1px solid #ebecf5;
        
        .MuiTableCell-root {
          font-size: $font-size-sm;
        }
        .MuiTableHead-root {
          .MUIDataTableHeadCell-fixedHeader-245 {
            background-color: inherit;
          }
        }
  
        .MuiTableBody-root {
  
  
          .MuiTableRow-root {
            cursor: pointer;
            
            &:nth-child(odd) {
              background-color: #ebecf5;
            }
            &.selected {
              background-color: rgba(red, 0.3);
            }
          }
        }
      }
    }
    .contract-specification {
      width: 100%;
      height: 55%;

      padding-top: 1rem;
      border-top: 1px solid #d3d2d2;

      .contract-attr {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0;
        align-items: center;
        height: 20px;
        
        label {
          font-weight: normal;
          text-align: center;
          white-space: nowrap;
        }
        .input-maru-custom.simple {
          background-color: white;
          padding: 0 0.2rem;
        }
        .MuiInputBase-input.MuiInput-input {
          height: 0.5rem;
        }

      }
    }
  }
}

.welcome-contents {
  margin-top: 2rem;

  .welcome-message {
    padding: 2rem;
    h1 {
      font-size: 3rem;
      text-align: center;
    }
    p {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  .guide-buttons {
    margin-top: 3rem;
    padding: 2rem;
    text-align: center;
    
    @include media-breakpoint-up(sm) {
      div.MuiGrid-item + div.MuiGrid-item {
        border-left: 1px solid #c8cbea;
      }
    }
    @include media-breakpoint-down(sm) {
      div.MuiGrid-item + div.MuiGrid-item {
        padding-top: 2rem;
        border-top: 1px solid #c8cbea;
      }
    }
  }
}