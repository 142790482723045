.logo-style {
  width: 8rem;
  height: 7rem;
  background-image: url('./images/logos/white/8@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
}
.gov-logo-style {
  width: 8rem;
  height: 7rem;
  background-image: url('./images/logos/white/gov/8@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.email-checkbox-label {
  margin-left: 20px;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ed1b23 !important;
}