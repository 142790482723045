// Collapsed Sidebar
@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed {
    .app-sidebar {
      width: $sidebar-width-collapsed;
      position: fixed;
      left: 0;
      display: flex;

      &:hover:not(.app-sidebar--mini) {
        width: $sidebar-width;
        position: fixed;
        box-shadow: $sidebar-shadow;

        .openmaru-logo {
          width: 87%;
          height: 70%;
          margin-left: 0;
          background-image: url("../../../../assets/images/logo.png");;
        }

        .gov-openmaru-logo {
          width: 87%;
          height: 70%;
          margin-left: 0;
          background-image: url("../../../../assets/images/logo_gov.png");;
        }
      }
    }
  }
}
