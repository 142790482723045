@import "widgets/helpers-utilities/scss/functions";
@import "widgets/helpers-utilities/scss/mixins";
@import "core/variables";

body {
	.MuiPaper-root, [class^=MUIDataTable-paper-] {
		border-radius: 0.75rem;
		box-shadow: 
				0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 
				0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03),
				0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 
				0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);

		.MuiTable-root {
			.MuiToolbar-root {
				[class^=MUIDataTableToolbar-actions-] {
					.custom-toolbar-icon:hover {
						color: #3c44b1;
					}
				}
			}
			.MuiTableHead-root {
				.MuiTableRow-head {
					.MuiTableCell-head {
						font-weight: bold;
						padding: 4px;
						div, span {
								text-align: center;
								justify-content: center;
								align-items: center;
						}
					}
				}
			}
			.MuiTableBody-root {
				.MuiTableRow-root {
					.MuiTableCell-root {
						@include media-breakpoint-down(sm) {
							width: 90vw !important;
							min-width: none !important;
							max-width: none !important;

							.text-overflow-ellipsis {
								text-align: center !important;
							}	
						}
						&.MuiTableCell-sizeSmall {
							padding: 4px 4px;
						}

						.MuiInputBase-root {
							&.MuiInput-underline {
								&::before {
									border-bottom: none;
								}
								&::after {
									border-bottom: none;
								}
							}
						}
						// Custom Button UI
						button.access-key-button {
							cursor: default;
						}
						.text-overflow-ellipsis {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}
				}
			}
			.MuiTableFooter-root {
				.MuiTableRow-footer {
					.MuiTableCell-footer {
						border-bottom: 0;
					}
				}
			}
		}
	}
}

