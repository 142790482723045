//  Core

.app-sidebar {
  &--header {
    padding: 0 0 0 ($layout-spacer / 4);
    min-height: $header-height-sm;
    height: $header-height-sm;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    // margin-bottom: ($layout-spacer / 4);

    .expand-sidebar-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &::after {
      content: "";
      width: 100%;
      left: 0;
      bottom: 0;
      height: 1px;
      position: absolute;
    }
  }

  .toggle-mobile-sidebar-btn {
    display: none;
    padding: 0;

    .MuiButton-label {
      display: flex;
    }
  }

  // Responsive

  @include media-breakpoint-down(md) {
    .toggle-mobile-sidebar-btn {
      display: block;
    }
  }
}

// Logo wrapper

.app-sidebar-logo {
  display: flex;
  align-items: center;
  transition: all 0.5s $app-sidebar-transition;
  width: 100%;
  height: 100%;

  &--icon {
    @include border-radius($border-radius-sm);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;

    .openmaru-logo {
      background-image: url("../../../assets/images/logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0;
  
      width: 87%;
      height: 70%;

      &.small {
        width: 52px;
        margin: 0 auto;
        background-image: url('../../images/logos/logo192.png');
      }
    }
    .gov-openmaru-logo {
      background-image: url("../../../assets/images/logo_gov.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0;
  
      width: 87%;
      height: 70%;

      &.small {
        width: 52px;
        margin: 0 auto;
        background-image: url('../../images/logos/logo192.png');
      }
    }
  }

  &--text {
    line-height: 1;
    padding: 0 0 0 ($spacer / 1.5);
    transition: all 0.5s $app-sidebar-transition;

    span {
      display: block;
      text-transform: uppercase;
      font-size: $font-size-sm / 1.2;
      color: $white;
      opacity: .7;
      padding: 4px 0;
    }

    b {
      font-size: $font-size-lg * 1.1;
      font-weight: bold;
      color: $white;
      text-transform: lowercase;
    }
  }

  &:hover {
    color: $black;
  }
}

@-webkit-keyframes logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

// Themes

@import "themes/dark";
@import "themes/light";

// custom Theme

@import "themes/custom";

// Modifiers

@import "modifiers/collapsed";
