@import "widgets/helpers-utilities/scss/functions";
@import "core/variables";

.price-tag-table {
  .price-tag-row {
    border-color: $black;
    border-width: 1px;
    border-style: solid;

    > div {
      display: inline-block;
      width: 50%;
      text-align: center;
      padding: 1rem 0;
    }

    &.price-tag-row+.price-tag-row {
      border-top-width: 0;
    }
  }

  .product-name {
    background-color: $gray-bg-color;
  }
  .product-price {
  }
}