// Helpers

// Generic

* {
  outline: none;
}

// Close button

.close {
  &:active,
  &:focus {
    outline: none;
  }
}

a {
transition: color .2s;
}

.z-over {
  position: relative;
  z-index: 25;
}

.left-0 {
  left: 0 !important;
}

.z-below {
  z-index: 4;
  position: relative;
}

.pulse-animation {
  transition: $transition-base;
  transform: scale(1.1);
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(.75);
  }
  20% {
    transform: scale(1.1);
  }
  40% {
    transform: scale(.75);
  }
  60% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(.75);
  }
  100% {
    transform: scale(.75);
  }
}

// Border radius

.br-tl {
  border-top-left-radius: $border-radius-sm !important;
}

.br-tr {
  border-top-right-radius: $border-radius-sm !important;
}

.br-bl {
  border-bottom-left-radius: $border-radius-sm !important;
}

.br-br {
  border-bottom-right-radius: $border-radius-sm !important;
}

body {

  .bl-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .br-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

}

body {
  @include media-breakpoint-up(lg) {
    .br-lg-right-0 {
      @include border-right-radius(0 !important);
    }

    .br-lg-left-0 {
      @include border-left-radius(0 !important);
    }
  }

  @include media-breakpoint-up(xl) {
    .br-xl-right-0 {
      @include border-right-radius(0 !important);
    }

    .br-xl-left-0 {
      @include border-left-radius(0 !important);
    }
  }
}

.progress-constrained {
  box-sizing: content-box;
}

// Opacity

@for $o from 1 through 9 {
  .opacity-#{$o} {
    opacity: $o / 10 !important;
  }
}

// Sizing

$num: 20;
@while $num < 150 {
  .d-#{$num} {
    height: $num +0px !important;
    line-height: $num +0px !important;
    width: $num +0px !important;
  }
  $num: $num + 10;
}

// Line height

.line-height-sm {
  line-height: 1.3;
}

.line-height-md {
  line-height: 2;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-normal {
  line-height: normal;
}

.text-indent-0 {
  text-indent: 0 !important;
}

// Height

.h-auto {
  height: auto;
}

.h-100 {
  height: 100%;
}

.h-20px {
  height: 20px;
}

.h-50px {
  height: 50px;
}

.h-180px {
  height: 180px;
}

.h-240px {
  height: 240px;
}

.h-280px {
  height: 280px;
}

.h-300px {
  height: 300px;
}

.h-320px {
  height: 320px;
}

// Width

.w-auto {
  width: auto;
}

.w-43 {
  width: 43%;
}

.w-50 {
  @include media-breakpoint-up(md) {
    min-width: 50%;
    width: 50%;
  }
}

@include media-breakpoint-down(sm) {
  body {
    .w-50 {
      width: 100% !important;

    }
  }
}

.max-w-70 {
  max-width: 70%;
}

// Divider

.divider {
  background: $border-color;
  height: 1px;
  overflow: hidden;
}

// Border

.border-1 {
  border-width: 1px !important;
  border-style: solid !important;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid !important;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid !important;
}

// Font size

.font-size-xs {
  font-size: $font-size-xs / 1.2 !important;
}

.font-size-sm {
  font-size: $font-size-sm !important;
}

.font-size-md {
  font-size: $font-size-base !important;
}

.font-size-lg {
  font-size: $font-size-lg !important;
}

.font-size-xl {
  font-size: $font-size-lg * 1.1 !important;
}

.font-size-xxl {
  font-size: $font-size-lg * 1.2 !important;
}

// Composed backgrounds

%composed-bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: .15;
}

.bg-composed-wrapper {
  position: relative;
  @include border-top-radius(inherit);
  display: flex;
  align-items: center;

  &--image {
    filter: grayscale(80%);
    background-size: cover;
    @include border-top-radius(inherit);
    @extend %composed-bg;
  }

  &--content {
    position: relative;
    z-index: 7;
    width: 100%;
  }

  &--bg {
    @extend %composed-bg;
    z-index: 5;
  }
}

.bg-composed-filter-rm {
  filter: grayscale(0%);
}

.bg-repeat {
  background-size: auto;
}

.bg-filter-grayscale {
  filter: grayscale(100%);
}

.transition-base {
  transition: $transition-base !important;
}

// Shadow

.shadow-xxl {
  box-shadow: $box-shadow-xxl !important;
}

.shadow-sm-dark {
  box-shadow: $box-shadow-sm-dark !important;
}

// Background white 50%

.bg-white-50 {
  background-color: transparentize($white, .8);
}

// Text dark

.text-black {
  color: $black;
}

// Images

.img-fit-container {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// Gradient Icons

.gradient-icon {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

// Blockquote

.blockquote {
  font-family: $font-family-monospace;
}

// Position

.p-top-a {
  top: auto !important;
}
.pos-r {
  left: auto;
  right: 0;
}

.hover-scale-sm {
  transition: $transition-base !important;
  transform: scale(1)!important;

  &:hover {
   transform: scale(1.08)!important;
  }
}

.hover-scale-rounded {
  transition: $transition-base !important;
  transform: scale(1)!important;
  box-shadow: none;

  &:hover {
    @include border-radius($border-radius !important);
    transform: scale(1.08)!important;
    box-shadow: $box-shadow-sm-light;
  }
}

.hover-scale-lg {
  transition: $transition-base !important;
  transform: scale(1)!important;

  &:hover {
   transform: scale(1.12) !important;
  }
}

@include media-breakpoint-up(lg) {
  .object-skew {
    transform: scale(0.95) perspective(924px) rotateY(-8deg) rotateX(9deg) rotate(3deg) !important;
  }
}

// Responsive rounded

@include media-breakpoint-down(xl) {
  .rounded-right,
  .rounded-left {
    border-radius: 0 !important;
  }
}

.d-44 {
  height: 44px;
  line-height: 44px;
  width: 44px;
}

.nav-link {
  cursor: pointer;
}


.tx-center {
  text-align: center;
}

.tx-right {
  text-align: right;
}

.padding-left-right-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.text-reduction {
  display: block;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

// Custom Helpers
@mixin dropzone-custom {
  .dropzone {
    background-color: $gray-bg-color;
    border-bottom: 2px solid #d3d2d2;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    .dropzone-inner-wrapper {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;   
      background-color: $white;

      >div {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      input + div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .btn-icon {
          color: $maru-text-color;
        }
      }
    }
  }
}

.text-maru-color {
  color: $maru-text-color !important;
}

.maru-box-shadow {
  border-radius: 0.5rem;
  box-shadow: 0px 22px 57px 0 rgba(0, 0, 0, 0.16);
}

@mixin button-maru {
  color: #ffffff !important;
  border-radius: 0.5rem !important;
  box-sizing: border-box !important;
}
.button-maru-contained {
  @include button-maru ;
  background-color: #ed1b23 !important;
  border: 2px solid transparent !important;
}

.button-maru-outlined {
  @include button-maru;
  background-color: transparent !important;
  border: 2px solid #ed1b23 !important;
  color: #ed1b23 !important;
  box-sizing: border-box !important;

  &:hover {
    background-color: #ed1b23 !important;
    color: #ffffff !important;
    border: 2px solid transparent !important;;
    box-sizing: content-box !important;
  }
}
.button-maru-reversed {
  @include button-maru;
  background-color: transparent !important;
  border: 2px solid #ffffff !important;
  color: #ffffff !important;
  box-sizing: border-box !important;
}

.button-maru-gray-contained {
  @include button-maru;
  background-color: #666666 !important;
  border: 2px solid transparent !important;
}
.button-maru-gray-outlined {
  @include button-maru;
  background-color: transparent !important;
  border: 2px solid #666666 !important;
  color: #666666 !important;
  box-sizing: border-box !important;

  &:hover {
    background-color: #666666 !important;
    color: #ffffff !important;
    border: 2px solid transparent !important;;
    box-sizing: content-box !important;
  }
}

.button-maru-second-contained {
  @include button-maru;
  background-color: #3c3f65 !important;
  border: 2px solid transparent !important;
}
.button-maru-second-outlined {
  @include button-maru;
  background-color: transparent !important;
  border: 2px solid #3c3f65 !important;
  color: #3c3f65 !important;
  box-sizing: border-box !important;

  &:hover {
    background-color: #3c3f65 !important;
    color: #ffffff !important;
    border: 2px solid transparent !important;;
    box-sizing: content-box !important;
  }
}

.input-maru-custom {
  margin: 0;
  background-color: $gray-bg-color;
  border-bottom: 2px solid #d3d2d2;

  &.MuiInput-underline {
    padding: 2px;
    padding-bottom: 0;

    &:before,&:hover:not(.Mui-disabled):before,&:after {
      border: 0;
      transition: none;
      border-color: #d3d2d2;
    }

    .MuiSelect-select {
      padding-left: 10px;
    }
  }

  >div {
    border-bottom: 2px solid #d3d2d2;

    &.Mui-error {
      border: 1px solid $maru-text-color;
    }

    &.MuiInput-underline {
      padding: 0 14px;

      &:before,&:hover:not(.Mui-disabled):before,&:after {
        border: 0;
        transition: none;
        border-color: #d3d2d2;
      }
    }
    &.MuiInputBase-root.Mui-focused {
      transition: all 0.2s ease-in-out;
      box-shadow: 
        0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
    }
  }
  fieldset {
    border: none;
  }
  p.MuiFormHelperText-root {
    background-color: #ffffff;
    margin: 0;
  }
  

}

.dialog-maru-custom.MuiPaper-root.MuiDialog-paper {
  @include dropzone-custom;

  border-radius: 0.5rem;
  padding: 0;
  padding-left: 3%;
  background-image: linear-gradient(to top, #ed1b23, #f65056);
  >div {
    background-color: $white;
    border: 0;
  }

  #alert-dialog-title {
    padding: 24px;
    .MuiTypography-root {
      color: $maru-text-color;
      font-size: 1.3rem;
    }
  }

  .MuiDialogActions-root {
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
    >:not(:first-child) {
      margin-left: 0;
    }

    button {
      margin: 0;
    }
  }

  label + .MuiInput-formControl {
    margin: 0;
    background-color: $gray-bg-color;
    border-bottom: 2px solid #d3d2d2;

    &.MuiInput-underline {
      padding: 2px;
      padding-bottom: 0;

      &:before,&:hover:not(.Mui-disabled):before,&:after {
        border: 0;
        transition: none;
        border-color: #d3d2d2;
      }

      .MuiSelect-select {
        padding-left: 10px;
      }
    }
  }

  .input-maru-custom {
    .MuiFormHelperText-root {
      font-size: 0.7rem;
    }
  }

  .quill {
    background-color: $gray-bg-color;
    .ql-toolbar {
      border: 0;
    }
    .ql-container {
      border: 0;
      border-top: 1px solid #d3d2d2;
      border-bottom: 2px solid #d3d2d2;
      height: 340px;
    }
  }

  // tutorial dialog style
  #tutorial-dialog-title {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0 20px;
    font-size: 16px;
    color: rgba(237, 27, 35, 0.5);

    div.dialog-focus-title {
      padding-top: 0;
      font-size: 22px;
      color: $maru-text-color;
    }
    .basic-title {
      padding-top: 5px;
    }
  }

}

.card-maru-custom {
  @include dropzone-custom;
  
  h1 {
    color: #333333
  }
  h1 + p {
    color: #333333;
    font-size: $font-size-lg;
  }
  
  .divider {
    background-color: #eeeeee;
  }

  .project-info-grid.MuiGrid-container {
    @media screen and (max-width: 576px) {
      flex-direction: column;
    }
    @media screen and (min-width: 576px) {
      flex-direction: row;
    }
  }

  @mixin list-title {
    >div:first-child {
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
  
      svg {
        font-size: 0.3rem;
      }
      span {
        margin-left: 0.6rem;
      }
    }
  }

  .list-item-maru-custom {
    @include list-title;

    div+div {
      background-color: $gray-bg-color;
      padding: 0.6rem 0.9rem;

      button {
        width: 18px;
        height: 18px;
      }

      &.logo-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
        p {
          text-align: center;
          margin: 0
        }
        svg {
          font-size: 2rem;
        }
      }
    }
    
    .text-value-point {
      color: #18247a;
    }
  }

  .list-item-maru-answer {
    @include list-title;

    .bubble-area {
      background-color: #f8f9ff;
      height: auto;
      .chat-box {
        background-color: $gray-bg-color;
      }
    }
    .answer-text-area {
      padding-top: 1rem;
      background-color: #f8f9ff;
      height: calc(200px + 4rem);

      .quill {
        height: 200px;
        .ql-toolbar {
          background-color: #ffffff;
        }
        .ql-container {
          .ql-editor {
            background-color: #ffffff;
          }
        }
      }
      .button-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        height: 240px;
        white-space: nowrap;
        padding: 0.5rem;
      }
    }
  }
}

.textCancelLine {
  text-decoration: line-through;
  color: #7b7b7b;
}

.box-shadow-none {
  box-shadow: none;
  &:hover, &:focus, &active {
    box-shadow: none;
  }
  &::after, &::before, &:after, &:before {
    box-shadow: none;
  }

  >div {
    &.MuiInputBase-root.Mui-focused {
      box-shadow: none;
    }
  }
}

.text-overflow-ellipsis {
  text-overflow : ellipsis;
  overflow      : hidden;
  white-space   : nowrap;
}

$partner-grade-color: (
  "default": #DEB887,
  "registered": #228B22,
  "silver": #C0C0C0,
  "advanced": #0000CD,
  "premium": $maru-main-color
);

@each $level, $value in $partner-grade-color {
  .partner-bg-color-#{$level} {
    background-color: $value;
    color: white;
  }
}

.button-maru-circle-outlined {
  $size: 7rem;
  border: 2px solid #3c3f66 !important;
  border-radius: calc(7rem/2) !important;
  background-color: $white !important;
  width: $size;
  height: $size;
  
  .btn-wrapper--icon {
    color: #3c3f65;
    font-size: 2.5rem;
  }

  &:hover {
    background-color: #3c3f65 !important;
    .btn-wrapper--icon {
      color: white;
    } 
  }
}