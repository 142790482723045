.messages-alarm {
  .MuiPaper-root {
    background-color: $gray-200;
    border-radius: 0.2rem;
    padding: 0;
    
    @include media-breakpoint-up(lg) {
      width: 26%;
    }
    
    @include media-breakpoint-down(lg) {
      width: 400px;
      .message-content-box {
        flex-direction: column;
        div+div {
          justify-content: flex-end;
        }
      }
    }
    
  }
}