@import "widgets/helpers-utilities/scss/functions";
@import "core/variables";

// Toast UI
.Toastify__toast-container {
  width: 460px;
  
  .toast {
    width: 450px;
    height: 100px;
  
    &.Toastify__toast {
        &--info {
            background-color: $primary;
        }
        &--warning {
            background-color: $warning;
        }
        &--error {
            background-color: $danger;
        }
    }
    .contents {
        display: flex;
        flex-direction: row;
    
        .icon-area {
            flex: 1;
            display: flex;
            align-items: center;
            .icon {
                width: 80%;
                height: 80%;
            }
        }
    
        .message-area {
            flex: 4;
  
            .title {
                font-weight: bold;
            }
  
            .subtext {
              overflow : hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              width:350px;
              z-index: 1000;
            }
        }
    }
  }
}
