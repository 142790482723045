// Sidebar menu dark color scheme
.app-sidebar {

    &--custom {
      .sidebar-navigation {
        .project-select-name {
          border: 1px solid rgba($white, .35);
          border-radius: 5px;
          margin: 0 10px;
          padding: 0 14px 2px 14px;
          color: rgba($white, .35);

          .project-select-label {
            padding: 0 14px;
            color: rgba($white, .35);
          }

          .project-select-list {
            font-size: $sidebar-navlink-font-size;
            &::before {
              border-bottom: 0;
            }
            &::after {
              border-bottom: 0;
            }

            .MuiSelect-selectMenu {
              color: $white;
            }
          }
          .MuiSelect-icon {
            color: $white;
          }
        }

        .menu-divider {
          margin: 0 15px;
          margin-top: 0.5rem;
        }
  
        .sidebar-menu-box {
          background: rgba($maru-bg-color, .15);
        }
  
        .sidebar-header {
          color: rgba($white, .35);
        }
  
        ul {
          li {
            & > a {
              color: rgba($white, .65);
  
              .sidebar-icon {
                & > * {
                  color: $white;
                  opacity: .3;
                }
              }
            }
  
            ul {
              li {
                a {
                  &::after {
                    background: rgba($maru-bg-color, .2);
                  }
  
                  &:hover,
                  &.active {
                    &::after {
                      background: rgba($maru-bg-color, .5);
                    }
                  }
                }
              }
            }
  
            & > a:hover, a.active {
              color: rgba($white, 1);
              background: rgba($maru-bg-color, .15);
  
              .sidebar-icon-indicator {
                opacity: .7;
              }
  
              .sidebar-icon {
                & > * {
                  opacity: 1;
                }
              }
            }


            & > a.nav-link-simple.active {
              color: rgba($red, 1);
              background: rgba($white, 1);
  
              .sidebar-icon-indicator {
                opacity: .7;
              }
  
              .sidebar-icon {
                & > * {
                  opacity: 1;
                  color: $red;
                }
              }
            }
          }
        }
      }
    }
  }
  .app-sidebar-collapsed {
    .app-sidebar--custom:not(:hover) {
      .app-sidebar--content {
        .sidebar-navigation {
          .sidebar-header {
            &::after {
              background: rgba($maru-bg-color, .15);
            }
          }
        }
      }
    }
  }
  