//  Core

.dropdown-mega-menu-lg {
  width: 54rem;

  @include media-breakpoint-down(lg) {
    width: 30rem;
  }
}

.dropdown-mega-menu-md {
  width: 25rem;
}

.app-header-menu {
  position: relative;
  transition: $transition-base;
  transform: scale(1);
  visibility: visible;
  transition-delay: .2s;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &.custom {
    height: 100%;

    button {
      background-color: inherit;
      border-radius: 0;
      border-top: 2px solid transparent;
      height: 100%;

      &:hover {
        color: $white;
        background-color: inherit;
        border-top: 2px solid $maru-main-color;
        
      }
    }
  }
}

// Custom Header Menus Style sheet

